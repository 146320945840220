import { ErrorHandler } from "@angular/core";
import StackdriverErrorReporter from "stackdriver-errors-js"

import { environment } from '../../environments/environment'

export class GlobalErrorHandler implements ErrorHandler {
  errorHandler: StackdriverErrorReporter;

  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      this.errorHandler = new StackdriverErrorReporter();

      this.errorHandler.start({
        key: environment.gcpApiKey,
        projectId: environment.gcpProjectId,
        service: 'sub-app'
      })
    });
  }

  handleError(err: unknown): void {
    this.errorHandler.report(err);
  }
}
