/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./loader/loader.component.ngfactory";
import * as i2 from "./loader/loader.component";
import * as i3 from "./main/main.component.ngfactory";
import * as i4 from "./main/main.component";
import * as i5 from "@angular/forms";
import * as i6 from "./success/success.component.ngfactory";
import * as i7 from "./success/success.component";
import * as i8 from "./accepted/accepted.component.ngfactory";
import * as i9 from "./accepted/accepted.component";
import * as i10 from "./error/error.component.ngfactory";
import * as i11 from "./error/error.component";
import * as i12 from "@angular/common";
import * as i13 from "./svg/svg-definitions.component.ngfactory";
import * as i14 from "./svg/svg-definitions.component";
import * as i15 from "./header/header.component.ngfactory";
import * as i16 from "./header/header.component";
import * as i17 from "./app.component";
import * as i18 from "@angular/router";
import * as i19 from "@angular/common/http";
import * as i20 from "@ngx-translate/core";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.LoaderComponent, [], null, null)], null, null); }
function View_AppComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-main", [], null, [[null, "formSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("formSubmit" === en)) {
        var pd_0 = (_co.save($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MainComponent_0, i3.RenderType_MainComponent)), i0.ɵdid(1, 114688, null, 0, i4.MainComponent, [i5.FormBuilder], { deliveryStage: [0, "deliveryStage"], tokenDecoded: [1, "tokenDecoded"], stageStatus: [2, "stageStatus"] }, { formSubmit: "formSubmit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.deliveryStage; var currVal_1 = _co.tokenDecoded; var currVal_2 = _co.stageStatus; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_AppComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-success", [], null, null, null, i6.View_SuccessComponent_0, i6.RenderType_SuccessComponent)), i0.ɵdid(1, 49152, null, 0, i7.SuccessComponent, [], { supplierName: [0, "supplierName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplierName; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-accepted", [], null, null, null, i8.View_AcceptedComponent_0, i8.RenderType_AcceptedComponent)), i0.ɵdid(1, 49152, null, 0, i9.AcceptedComponent, [], null, null)], null, null); }
function View_AppComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-error", [], null, null, null, i10.View_ErrorComponent_0, i10.RenderType_ErrorComponent)), i0.ɵdid(1, 49152, null, 0, i11.ErrorComponent, [], null, null)], null, null); }
function View_AppComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i0.ɵdid(2, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i0.ɵdid(4, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_5)), i0.ɵdid(6, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_6)), i0.ɵdid(8, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.deliveryStage && (_co.status !== _co.states.SUCCESS)) && (_co.stageStatus === "WAITING_FOR_ACTION")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.status === _co.states.SUCCESS); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.status !== _co.states.ERROR) && (_co.status !== _co.states.SUCCESS)) && (_co.stageStatus !== "WAITING_FOR_ACTION")); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.status === _co.states.ERROR); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i12.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-svg-definitions", [], null, null, null, i13.View_SvgDefinitionsComponent_0, i13.RenderType_SvgDefinitionsComponent)), i0.ɵdid(2, 49152, null, 0, i14.SvgDefinitionsComponent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(4, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-header", [], null, null, null, i15.View_HeaderComponent_0, i15.RenderType_HeaderComponent)), i0.ɵdid(6, 49152, null, 0, i16.HeaderComponent, [], { materialCategoryName: [0, "materialCategoryName"], supplierName: [1, "supplierName"], supplierPhone: [2, "supplierPhone"], constructionSiteAddress: [3, "constructionSiteAddress"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i0.ɵdid(8, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 4, "footer", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "span", [["class", "bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), i0.ɵppd(12, 2), (_l()(), i0.ɵted(-1, null, [" \u00A9 ProperGate\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.status === _co.states.PENDING) || !_co.translationLoaded); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.materialCategoryName; var currVal_2 = _co.supplierName; var currVal_3 = _co.supplierPhone; var currVal_4 = _co.constructionSiteAddress; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.translationLoaded; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v, 0), _co.now, "d/MM/yyyy H:mm")); _ck(_v, 11, 0, currVal_6); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i17.AppComponent, [i18.ActivatedRoute, i19.HttpClient, i20.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i17.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
