/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../svg/svg-icon.component.ngfactory";
import * as i3 from "../svg/svg-icon.component";
import * as i4 from "@angular/common";
import * as i5 from "./loader.component";
var styles_LoaderComponent = [i0.styles];
var RenderType_LoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
export function View_LoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "outer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-svg-icon", [["name", "logo"], ["size", "70"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 49152, null, 0, i3.SvgIconComponent, [i4.Location], { name: [0, "name"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "circle"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "logo"; var currVal_1 = "70"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_LoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i5.LoaderComponent, [], null, null)], null, null); }
var LoaderComponentNgFactory = i1.ɵccf("app-loader", i5.LoaderComponent, View_LoaderComponent_Host_0, {}, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
