import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { GlobalErrorHandler } from './helpers/global-error-handler';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { SvgDefinitionsComponent } from './svg/svg-definitions.component';
import { SvgIconComponent } from './svg/svg-icon.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { HeaderComponent } from './header/header.component';
import { SuccessComponent } from './success/success.component';
import { MainComponent } from './main/main.component';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule} from '@angular/common/http';
import { ErrorComponent } from './error/error.component';
import { AcceptedComponent } from './accepted/accepted.component';
import { environment } from 'src/environments/environment';

@NgModule({
    declarations: [
        SvgDefinitionsComponent,
        SvgIconComponent,
        LoaderComponent,
        HeaderComponent,
        AppComponent,
        SuccessComponent,
        MainComponent,
        ErrorComponent,
        AcceptedComponent,
    ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: environment.production ? GlobalErrorHandler : ErrorHandler
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
