import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';

import { GenericForm, Form, DeliveryStage } from '../interface';
import config from '../config';
import { TokenDecoded } from '../@consts/interfaces';
import { redirectToAppUrl } from '../helpers/redirect.helper';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  @Output() public formSubmit = new EventEmitter<any>();
  @Input() public deliveryStage: DeliveryStage;
  @Input() private tokenDecoded: TokenDecoded;
  @Input() public stageStatus: string;

  public form: GenericForm<Form>;
  public blue = config.blue;
  public times = config.times;
  public deliveryTime: string;
  public changedTime = '';
  public rescheduledStage: boolean;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.changedTime = moment(this.deliveryStage.from, 'HH:mm')
      .format('HH:mm');
    this.rescheduledStage = this.stageStatus !== 'WAITING_FOR_ACTION';
    this.deliveryTime = moment(this.deliveryStage.from, 'HH:mm')
        .format('HH:mm');
    this.form = this.fb.group({
      action: null,
      option: 'later',
      time: null,
    });

    this.form.valueChanges.pipe(filter(({ time }) => !!time)).subscribe(({ time, option }) => {
      const timeInMinutes = option === 'earlier' ? -time : time;
      this.changedTime = moment(this.deliveryTime, 'HH:mm')
        .add(timeInMinutes, 'm')
        .format('HH:mm');
    });
  }

  public redirectToApp() {
    window.open(redirectToAppUrl(this.deliveryStage));
  }

  public submit() {
    const { action, option, time } = this.form.value;
    const data: Form = { action };

    if (action === 'change') {
      data.time = option === 'earlier' ? -time : time;
    }

    this.formSubmit.emit(data);
  }
}
