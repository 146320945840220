/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./svg-icon.component";
import * as i2 from "@angular/common";
var styles_SvgIconComponent = [];
var RenderType_SvgIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SvgIconComponent, data: {} });
export { RenderType_SvgIconComponent as RenderType_SvgIconComponent };
export function View_SvgIconComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [[":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["xmlns", "http://www.w3.org/2000/svg"]], [[1, "height", 0], [1, "width", 0], [4, "display", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [], [[1, "fill", 0], [1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.height || _co.size); var currVal_1 = (_co.width || _co.size); var currVal_2 = _co.display; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.color; var currVal_4 = _co.iconUrl; _ck(_v, 1, 0, currVal_3, currVal_4); }); }
export function View_SvgIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-svg-icon", [], null, null, null, View_SvgIconComponent_0, RenderType_SvgIconComponent)), i0.ɵdid(1, 49152, null, 0, i1.SvgIconComponent, [i2.Location], null, null)], null, null); }
var SvgIconComponentNgFactory = i0.ɵccf("app-svg-icon", i1.SvgIconComponent, View_SvgIconComponent_Host_0, { name: "name", size: "size", height: "height", width: "width", display: "display", color: "color" }, {}, []);
export { SvgIconComponentNgFactory as SvgIconComponentNgFactory };
