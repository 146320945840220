/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../svg/svg-icon.component.ngfactory";
import * as i3 from "../svg/svg-icon.component";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./header.component";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-svg-icon", [["display", "inline"], ["name", "building"], ["width", "35"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 49152, null, 0, i3.SvgIconComponent, [i4.Location], { name: [0, "name"], width: [1, "width"], display: [2, "display"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "building"; var currVal_1 = "35"; var currVal_2 = "inline"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.constructionSiteAddress; _ck(_v, 4, 0, currVal_3); }); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-svg-icon", [["display", "inline"], ["name", "product"], ["width", "35"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 49152, null, 0, i3.SvgIconComponent, [i4.Location], { name: [0, "name"], width: [1, "width"], display: [2, "display"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " ", " ", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "product"; var currVal_1 = "35"; var currVal_2 = "inline"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.materialCategoryName; var currVal_4 = i1.ɵunv(_v, 4, 1, i1.ɵnov(_v, 5).transform("general.from")); var currVal_5 = _co.supplierName; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-svg-icon", [["name", "phone"], ["size", "40"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(8, 49152, null, 0, i3.SvgIconComponent, [i4.Location], { name: [0, "name"], size: [1, "size"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.constructionSiteAddress; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.supplierName; _ck(_v, 5, 0, currVal_1); var currVal_3 = "phone"; var currVal_4 = "40"; _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ("tel:" + _co.supplierPhone); _ck(_v, 6, 0, currVal_2); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 49152, null, 0, i6.HeaderComponent, [], null, null)], null, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i6.HeaderComponent, View_HeaderComponent_Host_0, { materialCategoryName: "materialCategoryName", supplierName: "supplierName", supplierPhone: "supplierPhone", constructionSiteAddress: "constructionSiteAddress" }, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
